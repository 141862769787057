/* eslint-disable no-undef */
const GlobalStyle = {
  blue1: "#1E3A5F",

  blue2: "#4D658D",
  blue3: "#CEE7FE",
  black1: "#374357",
  black2: "#0F1C2E",
  ABOUT_US_BACKGROUND_COLOR: "#203B60",
  ABOUT_US_DESCRIPTION_COLOR: "#CEE7FE",
  ABOUT_US_DESCRIPTION1_COLOR: "#ACC2EE",
  KNOW_MORE_COLOR: "#3C5B80",
  OUR_SOLOUTIONS_HEADING: "#1E3A5F",
  OUR_SOLOUTIONS_DESCRIPTION: "#1E3A5F",
  OUR_SERVICE_HEADING: "#000000",
  FOOTER_BACKGROUND_COLOR: "#203B60",
  TEXT_COLOR: "#FFFFFF",
};

export default GlobalStyle;
